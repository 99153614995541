import type { Transport } from '@sveltejs/kit';
import { ObjectId } from 'bson';

function isObjectId(obj: unknown): obj is ObjectId {
    return (
        typeof obj === 'object' &&
        obj !== null &&
        'toHexString' in obj &&
        'getTimestamp' in obj &&
        'equals' in obj &&
        obj.toHexString instanceof Function &&
        obj.getTimestamp instanceof Function &&
        obj.equals instanceof Function &&
        obj.toHexString().length === 24
    );
}

export const transport: Transport = {
    ObjectId: {
        encode: (value: unknown) => {
            return isObjectId(value) && value.toHexString();
        },
        decode: (value: string) => {
            return new ObjectId(value);
        },
    },
};

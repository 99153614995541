import { validateObjectIdString } from '../helpers/validate-object-id-string';

export function match(param: string) {
    try {
        validateObjectIdString(param);
        return true;
    } catch (e) {
        return false;
    }
}

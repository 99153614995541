import { error } from '@sveltejs/kit';

/**
 * Based on this:
 * https://github.com/Automattic/mongoose/issues/1959#issuecomment-97457325
 *
 * @export
 * @param {string} input
 */
export function validateObjectIdString(input: string): void {
    if (typeof input !== 'string') {
        error(400, `You must provide a string`);
    }
    if (!input.match(/^[a-fA-F0-9]{24}$/)) {
        error(400, `input must be an ObjectId`);
    }
}
